.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.Team-image {
  max-width: 100%;
  width: 500px;
  margin: 20px 0;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .Team-image {
    width: 90%;
  }
}

.Team-image:hover {
  transform: scale(1.05);
}

.Mission-statement p {
  padding: 10px;
}

.Roster {
  margin-top: 20px;
}

.Roster h2 {
  margin-bottom: 10px;
  cursor: pointer;
}

.Roster h2::after {
  content: '▶';
  display: inline-block;
  margin-left: 5px;
  transition: transform 0.3s ease;
  font-size: 0.5em;
  vertical-align: middle;
}

.Roster h2.open::after {
  transform: rotate(90deg);
}

.Roster ul {
  list-style-type: none;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.Roster ul.open {
  max-height: 1000px;
  transition: max-height 0.5s ease-in;
}

.Roster li {
  margin-bottom: 10px;
}

.Roster a {
  color: #000000;
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.Roster a:hover {
  text-decoration: none;
  font-size: 1.1em;
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes fall {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes sway {
  0% { transform: translateX(-15px); }
  100% { transform: translateX(15px); }
}
